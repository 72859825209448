import * as z from "zod";

import { COUNTRY_VALUES } from "@cloudifybiz/lighthouse-core/constants";
import { emailSchema } from "@cloudifybiz/lighthouse-core/zod/schema/auth";
import { slugSchema } from "@cloudifybiz/lighthouse-core/zod/schema/misc";

export const newCompanyFormSchema = z.object({
  vatin: z
    .string({
      invalid_type_error: "VAT number needs to be a string",
      required_error: "VAT number is required",
    })
    .max(30, "VAT Number is too long"),
  name: z
    .string({
      invalid_type_error: "Company Name needs to be a string",
      required_error: "Company Name is required",
    })
    .max(255, "Company Name is too long"),
  slug: slugSchema,
  country: z.enum(COUNTRY_VALUES),
});

export type NewCompanyFormSchema = z.infer<typeof newCompanyFormSchema>;

export const billingInformationFormSchema = z.object({
  name: z
    .string({
      invalid_type_error: "Billing Name needs to be a string",
      required_error: "Billing Name is required",
    })
    .min(2, "Billing Name is too short")
    .max(255, "Billing Name is too long"),
  emailAddress: emailSchema
    .nullish()
    .transform((v) => (v && v?.length > 0 ? v : null)),
  phoneNumber: z
    .string({
      invalid_type_error: "Billing phone number needs to be a string",
      required_error: "Billing phone number is required",
    })
    .max(30, "Billing Phone Number is too long")
    .nullish()
    .transform((v) => (v && v?.length > 0 ? v : null)),

  streetAddress: z.string({
    invalid_type_error: "Street Address needs to be a string",
    required_error: "Street Address is required",
  }),
  city: z
    .string({
      invalid_type_error: "City needs to be a string",
      required_error: "City number is required",
    })
    .min(2, "City is too short")
    .max(255, "City is too long"),
  state: z
    .string({
      invalid_type_error: "State needs to be a string",
      required_error: "State is required",
    })
    .max(255, "State is too long")
    .nullish()
    .transform((v) => (v && v?.length > 0 ? v : null)),
  zip: z
    .string({
      invalid_type_error: "Zip Code needs to be a string",
      required_error: "Zip Code is required",
    })
    .min(2, "Zip Code is too short")
    .max(20, "Zip Code is too long"),
  twoDigitCountryCode: z
    .string({
      invalid_type_error: "Country needs to be a string",
      required_error: "Country is required",
    })
    .max(2, "Country is too long"),
});

export type BillingInformationFormSchema = z.infer<
  typeof billingInformationFormSchema
>;

const planMap = [
  {
    mSlug: "starter-marketplace",
    slug: "starter-monthly",
    annual: false,
  },
  {
    mSlug: "starter-marketplace",
    slug: "starter-yearly",
    annual: true,
  },
  {
    mSlug: "business [s]",
    slug: "business-monthly",
    annual: false,
  },
  {
    mSlug: "business [s]",
    slug: "business-yearly",
    annual: true,
  },
  {
    mSlug: "business-premium-marketplace",
    slug: "business-premium-monthly",
    annual: false,
  },
  {
    mSlug: "business-premium-marketplace",
    slug: "business-premium-yearly",
    annual: true,
  },
];

export const onboardingParamsSchema = z.object({
  plan: z
    .object({
      slug: z.string().optional(),
      currency: z
        .string()
        .optional()
        .transform((v) => (v ? v.toLowerCase() : undefined)),
      annual: z
        .union([z.boolean(), z.string()])
        .transform((v) => v === "true" || v === true)
        .optional(),
    })
    .optional()
    .transform((v) => {
      // TODO: Remove this once we have a better way to handle this
      if (!v || !v.slug) return v;
      const planM = planMap.find(
        (plan) => plan.mSlug === v.slug && plan.annual,
      );
      if (planM)
        return {
          ...v,
          slug: planM.slug,
        };
      return v;
    }),
  workflows: z.array(z.string()).optional(),
});

export const onboardingParamsWithFiltersSchema = onboardingParamsSchema.extend({
  filters: z
    .object({
      apps: z.array(z.string()).optional(),
      search: z.string().optional(),
    })
    .optional(),
});
export const bookMeetingFailedSchema = z.object({
  meetingBookFailed: z.boolean(),
  meetingsPayload: z.object({
    linkType: z.string(),
    offline: z.boolean(),
    userSlug: z.string(),
    formGuid: z.string(),
    bookingResponse: z.object({
      event: z.object({
        duration: z.number(),
        formFields: z.array(z.unknown()),
        dateString: z.string(),
        dateTime: z.number(),
      }),
      postResponse: z.null(),
    }),
    isPaidMeeting: z.boolean(),
  }),
});

export const bookMeetingSuccessSchema = z.object({
  meetingBookSucceeded: z.boolean(),
  meetingsPayload: z.object({
    linkType: z.string(),
    offline: z.boolean(),
    userSlug: z.string(),
    formGuid: z.string(),
    bookingResponse: z.object({
      event: z.object({
        duration: z.number(),
        formFields: z.array(z.unknown()),
        dateString: z.string(),
        dateTime: z.number(),
      }),
      postResponse: z.object({
        timerange: z.object({ start: z.number(), end: z.number() }),
        organizer: z.object({
          firstName: z.string(),
          lastName: z.string(),
          email: z.string(),
          fullName: z.string(),
          name: z.string(),
          userId: z.null(),
        }),
        bookedOffline: z.boolean(),
        contact: z.object({
          firstName: z.string(),
          lastName: z.string(),
          email: z.string(),
          fullName: z.string(),
          name: z.string(),
          userId: z.null(),
        }),
      }),
    }),
    isPaidMeeting: z.boolean(),
  }),
});

export const bookMeetingEventSchema = z.union([
  bookMeetingFailedSchema,
  bookMeetingSuccessSchema,
]);
